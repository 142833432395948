<template>
  <div class="u-page">
    <div class="u-header">
      <div class="userinfo">
        <div class="avatar">
          <img :src="userinfo.headimg" class="fit-img" alt="">
        </div>
        <div class="tips">请完善您的基本信息</div>
      </div>
    </div>
    <div class="page-main">
      <div class="block__hd flex-between">
        <h3 class="title">基础信息</h3>
        <div class="info">已完善资料{{ process }}%</div>
      </div>
      <div class="cell-block">
        <div class="cell">
          <div class="cell-main">
            <div class="cell-label">用户昵称</div>
            <div class="cell-inner">
              <span class="label">{{ userinfo.username }}</span>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="cell-main van-hairline--top">
            <div class="cell-label">会员积分</div>
            <div class="cell-inner">
              <span class="label">{{ userinfo.score }}</span>
            </div>
          </div>
        </div>
        <div class="cell hover" @click="$router.push('/account/')">
          <div class="cell-main van-hairline--top">
            <div class="cell-label">手机号码</div>
            <div class="cell-inner">
              <span class="label">{{ userinfo.mobile }}</span>
              <van-icon name="arrow" color="#9C9DAA" size="2.6667vw"/>
            </div>
          </div>
        </div>
        <div class="cell hover" @click="dateVisible=true">
          <div class="cell-main van-hairline--top">
            <div class="cell-label">出生日期</div>
            <div class="cell-inner">
              <span class="label">{{ birthStr || '请选择' }}</span>
              <van-icon name="arrow" color="#9C9DAA" size="2.6667vw"/>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="cell-main van-hairline--top">
            <div class="cell-label">性别</div>
            <div class="cell-inner">
              <div class="radio-item" :class="{ 'checked': gender == 2 }" @click="changeGender(2)">
                <div class="check-icon">
                  <img src="~@/assets/images/icon/icon_check.svg" alt="">
                </div>
                <span class="radio-label">女</span>
              </div>
              <div class="radio-item" :class="{ 'checked': gender == 1 }" @click="changeGender(1)">
                <div class="check-icon">
                  <img src="~@/assets/images/icon/icon_check.svg" alt="">
                </div>
                <span class="radio-label">男</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <!-- 生日弹窗 -->
    <van-popup
      v-model="dateVisible"
      position="bottom"
    >
      <van-datetime-picker
        v-model="birth"
        type="date"
        title="选择生日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirmBirth"
        @cancel="dateVisible=false"
      />
    </van-popup>
  </div>
</template>

<script>
  import { Toast, Icon, DatetimePicker, Popup } from 'vant'
  import { getUserInfo, updateUserInfo } from '@/api/user'
  import { parseTime } from '@/utils/common'

  export default {
    components: {
      [Icon.name]: Icon,
      [DatetimePicker.name]: DatetimePicker,
      [Popup.name]: Popup,
    },
    data() {
      return {
        userinfo: {},
        score: 0,
        level: '',
        gender: 0,
        birth: new Date(1990, 0, 1),
        minDate: new Date(1900, 0, 1),
        maxDate: new Date(),
        dateVisible: false,
        birthStr: '',
        process: ''
      }
    },
    created() {
      this.loadUserInfo()
    },
    methods: {
      loadUserInfo() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getUserInfo().then(res => {
          const userinfo = res.data.info
          this.userinfo = userinfo
          this.birth = userinfo.birth ? new Date(userinfo.birth) : new Date(1990, 0, 1)
          this.birthStr = userinfo.birth || ''
          this.gender = userinfo.sex || 0
          this.setProcess()
          toast.clear()
        })
      },
      setProcess() {
        let process = 0
        const userinfo = this.userinfo
        if (userinfo.username) {
          process += 25
        }
        if (userinfo.mobile) {
          process += 25
        }
        if (userinfo.birth) {
          process += 25
        } 
        if (userinfo.sex == 1 || userinfo.sex == 2) {
          process += 25
        } 
        this.process = process
      },
      /**
       * 选择生日
       */
      onConfirmBirth(e) {
        const birth = parseTime(e, '{y}-{m}-{d}')
        const params = {
          birth: birth
        }
        const toast = Toast.loading({
          message: '保存中...',
          forbidClick: true,
        })
        updateUserInfo(params).then(() => {
          toast.clear()
          this.dateVisible = false
          this.loadUserInfo()
        })
      },
      changeGender(e) {
        const params = {
          sex: e
        }
        const toast = Toast.loading({
          message: '保存中...',
          forbidClick: true,
        })
        updateUserInfo(params).then(() => {   
          toast.clear()
          this.loadUserInfo()
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>